.apparel-section {
  width: 100%;
  padding: 2rem 0;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instagram-embed-container {
  width: 100%;
  max-width: 540px;
  margin: 1rem auto;
}

.instagram-media {
  min-width: 100% !important;
  width: 100% !important;
  margin: 0 !important;
}

.order-link {
  margin: 1.5rem 0;
}

.order-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ffcc00;
  color: black;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.order-button:hover {
  background-color: #ffaa00;
}

.apparel-gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
  max-width: 1200px;
  padding: 0 1rem;
}

.gallery-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.apparel-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.image-caption {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #333;
  text-align: center;
}


@media (max-width: 768px) {
  .apparel-gallery {
    gap: 1rem;
  }
  
  .apparel-image {
    width: 150px;
    height: 150px;
  }
}